import axios from "axios";
import config from "../config";

const instance = axios.create({
  baseURL: config.host,
  responseType: "json",
  headers: axios.defaults.headers,
});

const isValidFunc = (func) => {
  return func !== null && typeof func === "function";
};

export const GET = (
  url,
  afterFunc = null,
  callbackSuccess = null,
  callbackError = null
) => {
  instance
    .get(url)
    .then((response) => {
      if (isValidFunc(callbackSuccess)) callbackSuccess(response);
      if (isValidFunc(afterFunc)) afterFunc();
    })
    .catch((error) => {
      if (isValidFunc(callbackError)) callbackError(error);
    });
};
export const POST = (
  url,
  data,
  afterFunc = null,
  callbackSuccess = null,
  callbackError = null
) => {
  instance
    .post(url, data)
    .then((response) => {
      if (isValidFunc(callbackSuccess)) callbackSuccess(response);
      if (isValidFunc(afterFunc)) afterFunc();
    })
    .catch((error) => {
      if (isValidFunc(callbackError)) callbackError(error);
    });
};
export const DELETE = (
  url,
  afterFunc = null,
  callbackSuccess = null,
  callbackError = null
) => {
  instance
    .delete(url)
    .then((response) => {
      if (isValidFunc(callbackSuccess)) callbackSuccess(response);
      if (isValidFunc(afterFunc)) afterFunc();
    })
    .catch((error) => {
      if (isValidFunc(callbackError)) callbackError(error);
    });
};
export const PUT = (
  url,
  afterFunc = null,
  callbackSuccess = null,
  callbackError = null
) => {
  instance
    .put(url)
    .then((response) => {
      if (isValidFunc(callbackSuccess)) callbackSuccess(response);
      if (isValidFunc(afterFunc)) afterFunc();
    })
    .catch((error) => {
      if (isValidFunc(callbackError)) callbackError(error);
    });
};
