import React, { useState } from "react";
import PropTypes from "prop-types";
import { Search } from "@vkontakte/vkui";
import Filter24Icon from "@vkontakte/icons/dist/24/filter";

/**
 * @param {func} openModal функция открытия модального окна
 */
const SearchCmp = ({ openModal }) => {
  return <Search icon={<Filter24Icon />} onIconClick={openModal} />;
};

SearchCmp.propTypes = {
  openModal: PropTypes.func,
};
export default SearchCmp;
