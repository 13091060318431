import React from "react";
import PropTypes from "prop-types";
import {
  FormLayout,
  Radio,
  SelectMimicry,
  FormLayoutGroup,
  Input,
  Button,
  Search,
} from "@vkontakte/vkui";

/**
 * Контент для модального окна "Фильтр"
 */
const SearchModal = ({ modalBack }) => (
  <FormLayout>
    <SelectMimicry top="Откуда" placeholder="Выбрать город" />
    <SelectMimicry top="Куда" placeholder="Выбрать город" />
    <FormLayoutGroup top="Дата">
      <Input type="date" />
    </FormLayoutGroup>
    <FormLayoutGroup top="Время">
      <Input type="time" />
    </FormLayoutGroup>
    <Button size="xl" onClick={modalBack}>
      Применить
    </Button>
  </FormLayout>
);

SearchModal.propTypes = {
  modalBack: PropTypes.func.isRequired,
};

export default SearchModal;
