import bridge from "@vkontakte/vk-bridge";
import { appTypes } from "../actionTypes/appTypes";
import { GET } from "../utils/api";

export const VkAppInit = () => {
  // Асинхронный thunk-экшен
  return (dispatch) => {
    // Диспатчим тип начала запроса, чтобы появилась иконка загрузки
    dispatch({ type: appTypes.REQUEST, payload: true });
    // Получаем инфу о пользователе
    bridge.send("VKWebAppGetUserInfo").then((data) => {
      // Гет запрос для получения данных по попуткам из города, который указан в профиле пользователя
      GET(
        `app/?city=${data.city.title}`,
        () => dispatch({ type: appTypes.REQUEST, payload: false }),
        (d) => dispatch({ type: appTypes.GET_TRIPS, payload: d.data })
      );
    });
  };
};

export const setActivePanel = (id) => {
  return {
    type: appTypes.SET_ACTIVE_PANEL,
    payload: id,
  };
};

export const setActiveModal = (id) => {
  return {
    type: appTypes.SET_ACTIVE_MODAL,
    payload: id,
  };
};
