import React from "react";
import PropTypes from "prop-types";
import {
  FormLayout,
  SelectMimicry,
  FormLayoutGroup,
  Input,
  Button,
  Div,
} from "@vkontakte/vkui";

/**
 * Контент для модального окна "Фильтр"
 */
const SearchModalPass = ({ modalBack }) => (
  <FormLayout>
    <Div>Вы будете получать уведомления о новых поездках, которые будут добавлены в указанный Вами промежуток времени</Div>
    <SelectMimicry top="Откуда" placeholder="Выбрать город" />
    <SelectMimicry top="Куда" placeholder="Выбрать город" />
    <FormLayoutGroup top="Начало промежутка времени">
      <Input type="time" />
    </FormLayoutGroup>
    <FormLayoutGroup top="Конец промежутка времени">
      <Input type="time" />
    </FormLayoutGroup>
    <Button size="xl" onClick={modalBack}>
      Применить
    </Button>
  </FormLayout>
);

SearchModalPass.propTypes = {
  modalBack: PropTypes.func.isRequired,
};

export default SearchModalPass;
