import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import {
  Header,
  PanelHeaderSimple,
  Separator,
  Epic,
  Tabbar,
  TabbarItem,
} from "@vkontakte/vkui";
import Trip from "../ui/Trip";
import TripInfo from "../ui/TripInfo";
import SearchCmp from "../ui/Search";
import ListTrips from "../ui/ListTrips";
import PANELS from "./PANELS_NAME";
import { MODAL_SEARCH } from "../modals/MODALS_NAME";
import _ from "lodash";
import COLORS from "../constants/COLORS";
import moment from "moment";

/**
 *
 * @param {string} id id формы
 * @param {func}  go метод перехода между панелями
 * @param {func} setActiveModal метод вызова модального окна
 * @param {array} trips массив поездок
 */
const Home = ({ id, go, setActiveModal, trips }) => {
  const [activeTab, setActiveTab] = useState("Companion");
  const [tripsState, setTrips] = useState();

  useEffect(() => {
    // Добавляем св-во к каждой поездке
    setTrips(
      trips.map((i) => ({ ...i, settings: { colorClick: COLORS.DEFAULT } }))
    );
  }, [trips]);

  const onClickTrip = (objectTrip) => {
    let copyState = [...tripsState];
    for (let i = 0; i < copyState.length; i++) {
      // Если id текущего объекта совпадает с объектом из параметра, то меняем флаг раскрытия
      if (copyState[i].id === objectTrip.id) {
        copyState[i].isFullInfo = !copyState[i].isFullInfo;
        copyState[i].settings.colorClick = COLORS.SELECTED_TRIP;
        // Смотрим соседние объекты и при необходимости меняем у них флаг
        if (
          copyState[i - 1] !== undefined &&
          copyState[i - 1].isFullInfo === true
        ) {
          copyState[i - 1].isFullInfo = false;
          copyState[i - 1].settings.colorClick = COLORS.DEFAULT;
        }
        if (
          copyState[i + 1] !== undefined &&
          copyState[i + 1].isFullInfo === true
        ) {
          copyState[i + 1].isFullInfo = false;
          copyState[i + 1].settings.colorClick = COLORS.DEFAULT;
        }
      }
    }
    setTrips([...copyState]);
  };

  let Trips = [];
  _.forEach(tripsState, (i, key) => {
    Trips.push(
      <Trip
        key={`${key}_${i.id}`}
        onClick={onClickTrip}
        objectTrip={i}
        name={
          <>
            {i.data.first_name + " " + i.data.last_name}
            <span
              style={{
                background: "#ff3347",
                padding: 3,
                borderRadius: 9,
                fontSize: 12,
                fontWeight: "bold",
                marginLeft: 5,
                color: "white",
              }}
            >
              {moment.unix(i.data.detail.timeToGo._seconds).format("HH:MM")}
            </span>
          </>
        }
        description={<TripInfo objectTrip={i} />}
      />
    );
  });
  return (
    <Panel id={id} separator={false}>
      <PanelHeaderSimple>Попутчики</PanelHeaderSimple>
      <Header>
        <SearchCmp openModal={() => setActiveModal(MODAL_SEARCH)} />
        {activeTab === "Companion" ? null : (
          <Div>
            <Button
              size="xl"
              mode="secondary"
              onClick={go}
              data-to={PANELS.CREATE_TRIP}
            >
              Создать поездку
            </Button>
          </Div>
        )}
      </Header>
      <Separator />
      <Epic>
        <Tabbar>
          <TabbarItem
            onClick={() => setActiveTab("Companion")}
            selected={activeTab === "Companion"}
          >
            Попутки
          </TabbarItem>
          <TabbarItem
            onClick={() => setActiveTab("MyTrips")}
            selected={activeTab === "MyTrips"}
          >
            Мои поездки
          </TabbarItem>
        </Tabbar>
      </Epic>
      {activeTab === "Companion" ? <Group>{Trips}</Group> : <ListTrips />}
    </Panel>
  );
};

Home.propTypes = {
  id: PropTypes.string,
  go: PropTypes.func,
  setActiveModal: PropTypes.func,
  setActiveTab: PropTypes.func,
  trips: PropTypes.array,
};

export default Home;
