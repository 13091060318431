import React, { useState } from "react";
import PropTypes from "prop-types";
import { ModalRoot } from "@vkontakte/vkui";
import SearchModal from "./SearchModal";
import SearchModalPass from "./SearchModalPass";
import Icon24Cancel from "@vkontakte/icons/dist/24/cancel";
import Icon24Done from "@vkontakte/icons/dist/24/done";
import {
  ModalPageHeader,
  ModalPage,
  PanelHeaderButton,
  IS_PLATFORM_ANDROID,
  IS_PLATFORM_IOS,
  Checkbox,
} from "@vkontakte/vkui";
import { MODAL_SEARCH } from "./MODALS_NAME";

/**
 * Главный контейнер для всех модальных окон
 * @param {string} modal название модального окна
 * @param {func} setActiveModal функция изменения модального окна *
 */
// Предполагалось, что будет содержать обычные модальные окна, описанные в других .js файлах
// Но почему-то обязательно в ModalRoot надо указывать именно ModalPage
const Modals = ({ modal, setActiveModal }) => {
  const [activeForm, setActiveForm] = useState("default");
  const modalBack = () => {
    setActiveModal(null);
  };
  return (
    <ModalRoot activeModal={modal} onClose={modalBack}>
      <ModalPage
        id={MODAL_SEARCH}
        onClose={modalBack}
        dynamicContentHeight
        header={
          <ModalPageHeader
            left={
              IS_PLATFORM_ANDROID && (
                <PanelHeaderButton onClick={modalBack}>
                  <Icon24Cancel />
                </PanelHeaderButton>
              )
            }
            right={
              <PanelHeaderButton onClick={modalBack}>
                {IS_PLATFORM_IOS ? "Готово" : <Icon24Done />}
              </PanelHeaderButton>
            }
          >
            Фильтры
          </ModalPageHeader>
        }
      >
        <Checkbox
          onClick={() =>
            activeForm === "default"
              ? setActiveForm("passenger")
              : setActiveForm("default")
          }
        >
          Получать уведомления о новых поездках
        </Checkbox>
        {activeForm === "default" ? (
          <SearchModal modalBack={modalBack} />
        ) : (
          <SearchModalPass modalBack={modalBack} />
        )}
      </ModalPage>
    </ModalRoot>
  );
};

Modals.propTypes = {
  modal: PropTypes.string,
  setActiveModal: PropTypes.func,
};

export default Modals;
