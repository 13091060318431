const initialState = {
  trips: [
    {
      id: 0,
      firstName: "Ашот",
      lastName: "Ашотович",
      whereFrom: "Екатеринбург",
      where: "Полевской",
      autoMark: "Audi",
      autoModel: "A3",
      autoNum: "В997НТ96",
      autoColor: "Красный",
      comment: "Стою у автошколы",
      time: "12:30",
      price: "100",
      countPlace: "3",
      isFullInfo: false,
    },
    {
      id: 1,
      firstName: "Владимир",
      lastName: "Путин",
      whereFrom: "Полевской",
      where: "Екатеринбург",
      autoMark: "Renault",
      autoModel: "Logan",
      autoNum: "Е777ЕЕ96",
      autoColor: "Синий",
      comment: "Тест 2",
      time: "17:00",
      price: "100",
      countPlace: "4",
      isFullInfo: false,
    },
  ],
};

const handlers = {
  DEFAULT: (state) => state,
};

export const homeReducer = (state = initialState, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT;
  return handler(state, action.payload);
};
