import React from "react";
import PropTypes from "prop-types";
import {
  Panel,
  PanelHeader,
  Button,
  PanelHeaderButton,
  Group,
} from "@vkontakte/vkui";
import { platform, IOS, List } from "@vkontakte/vkui";
import Icon28ChevronBack from "@vkontakte/icons/dist/28/chevron_back";
import Icon24Back from "@vkontakte/icons/dist/24/back";
import Trip from "./Trip";
import TripInfo from "./TripInfo";

const osName = platform();

const ListTrips = (props) => {
  const trips = [];
  for (let i = 0; i < 15; i++) {
    // trips.push(
    //   <Trip
    //     key={i + "_trip"}
    //     isList={true}
    //     description={<TripInfo name="Сергей Сергеевич" />}
    //   />
    // );
    // trips.push(
    //   <Trip
    //     key={i + "_trip1"}
    //     isList={true}
    //     description={<TripInfo name="Андрей Андреевич" />}
    //   />
    // );
  }
  return (
    <Group>
      <List>{trips}</List>
    </Group>
  );
};

ListTrips.propTypes = {
  id: PropTypes.string,
};

export default ListTrips;
