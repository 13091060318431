import React, { useState } from "react";
import PropTypes from "prop-types";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import {
  PanelHeaderSimple,
  PanelHeaderBack,
  Input,
  Radio,
  Select,
  FormLayoutGroup,
  Checkbox,
  Link,
  Button,
  Textarea,
  FormLayout,
} from "@vkontakte/vkui";
import PANELS from "./PANELS_NAME";

const CreateTrip = ({ id, go }) => {
  const [state, setState] = useState({
    time: "",
    date: "",
    where: "",
    from: "",
    auto: "",
  });

  const onChange = (e) => {
    const { name, value } = e.currentTarget;
    setState({ [name]: value });
  };

  return (
    <Panel id={id} separator={false}>
      <PanelHeaderSimple
        left={<PanelHeaderBack onClick={go} data-to={PANELS.INDEX} />}
      >
        Новая поездка
      </PanelHeaderSimple>
      <FormLayout>
        <Input
          type="time"
          top="Время"
          name="time"
          value={state.time}
          onChange={onChange}
          status={state.time ? "valid" : "error"}
          bottom={state.time ? "" : "Введите время поездки"}
        />
        <Input
          type="date"
          top="Дата"
          name="date"
          value={state.date}
          onChange={onChange}
          status={state.date ? "valid" : "error"}
          bottom={state.date ? "" : "Введите дату поездки"}
        />

        <FormLayoutGroup top="" bottom="">
          <Select
            top="Куда едем?"
            placeholder="Выберите пункт назначения"
            status={state.where ? "valid" : "error"}
            bottom={state.where ? "" : "Пожалуйста, укажите пункт назначения"}
            onChange={onChange}
            value={state.where}
            name="purpose"
          >
            <option value="0">Полевской</option>
            <option value="1">Екатеринбург</option>
          </Select>
          <Select
            top="Откуда поедете?"
            placeholder="Выберите точку отправления"
            status={state.from ? "valid" : "error"}
            bottom={state.from ? "" : "Пожалуйста, укажите точку отправления"}
            onChange={onChange}
            value={state.from}
            name="purpose"
          >
            <option value="0">Полевской</option>
            <option value="1">Екатеринбург</option>
          </Select>
        </FormLayoutGroup>
        <FormLayoutGroup>
          <Select
            top="Автомобиль"
            placeholder="Выберите Ваш автомобиль"
            status={state.auto ? "valid" : "error"}
            bottom={state.auto ? "" : "Пожалуйста, укажите автомобиль"}
            onChange={onChange}
            value={state.auto}
            name="purpose"
          >
            <option value="0">Рено логан</option>
            <option value="1">Аудюха</option>
          </Select>
        </FormLayoutGroup>

        <FormLayoutGroup>
          <Input
            type="number"
            disable
            placeholder="Укажите кол-во свободных мест"
          />
        </FormLayoutGroup>

        <Input type="number" placeholder="Укажите цену" />

        <Textarea top="Комментарий" />

        <Button size="xl" onClick={go} data-to={PANELS.INDEX}>
          Создать поездку
        </Button>
      </FormLayout>
    </Panel>
  );
};

CreateTrip.propTypes = {
  id: PropTypes.string,
  go: PropTypes.func,
};

export default CreateTrip;
