import { appTypes } from "../actionTypes/appTypes";
import PANELS from "../panels/PANELS_NAME";

const intialState = {
  activePanel: PANELS.INDEX,
  activeModal: null,
  isFetching: false,
  trips: [],
};

const handlers = {
  [appTypes.SET_ACTIVE_PANEL]: (state, payload) => ({
    ...state,
    activePanel: payload,
  }),
  [appTypes.SET_ACTIVE_MODAL]: (state, payload) => ({
    ...state,
    activeModal: payload,
  }),
  [appTypes.SET_USER]: (state, payload) => ({
    ...state,
    user: payload,
  }),
  [appTypes.REQUEST]: (state, payload) => ({
    ...state,
    isFetching: payload,
  }),
  [appTypes.GET_TRIPS]: (state, payload) => ({
    ...state,
    trips: payload,
  }),
  DEFAULT: (state) => state,
};

export const appReducer = (state = intialState, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT;
  return handler(state, action.payload);
};
