import React, { useState } from "react";
import PropTypes from "prop-types";
import { Cell, Avatar, Button, Counter } from "@vkontakte/vkui";
import Go from "@vkontakte/icons/dist/16/done";
import Ok from "@vkontakte/icons/dist/16/check_circle";
import MoreHorizontal from "@vkontakte/icons/dist/16/more_horizontal";

/**
 * Запись поездки
 * @param {node} description Описание в Cell
 * @param {node} name Компонент с именем
 * @param {bool} isList Попутки/Мои поездки
 * @param {object} objectTrip Объект поездки
 * @param {func} onClick Функция клика по кнопке "Подробнее"
 */
const Trip = ({ description, name, isList = false, onClick, objectTrip }) => {
  const [requestGo, SetRequestGo] = useState(false);
  const goTo = (
    <>
      <Counter size="s">Мест: {objectTrip.data.detail.countPlace}</Counter>
      <Button
        size="m"
        mode="secondary"
        style={{ marginTop: 5 }}
        onClick={() => onClick(objectTrip)}
      >
        <MoreHorizontal />
      </Button>
    </>
  );
  return (
    <Cell
      before={<Avatar src={objectTrip.data.photoUser} />}
      style={{ backgroundColor: objectTrip.settings.colorClick }}
      description={description}
      asideContent={isList || goTo}
      size="l"
      bottomContent={
        <div>
          <Button
            mode={!requestGo ? "primary" : "secondary"}
            disabled={requestGo === true}
            before={
              !requestGo ? (
                <Go style={{ marginRight: 5 }} />
              ) : (
                <Ok style={{ marginRight: 5 }} />
              )
            }
            onClick={() => SetRequestGo(true)}
          >
            Я поеду! ({objectTrip.data.detail.price}₽)
          </Button>
        </div>
      }
    >
      {name}
    </Cell>
  );
};

Trip.propTypes = {
  description: PropTypes.node,
  name: PropTypes.node,
  isList: PropTypes.bool,
  onClick: PropTypes.func,
  objectTrip: PropTypes.object,
};

export default Trip;
