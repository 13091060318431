import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import bridge from "@vkontakte/vk-bridge";
import View from "@vkontakte/vkui/dist/components/View/View";
import ScreenSpinner from "@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner";
import "@vkontakte/vkui/dist/vkui.css";
import HomePanel from "./panels/Home";
import Modals from "./modals/index";
import { connect } from "react-redux";
import CreateTrip from "./panels/CreateTrip";
import {
  setActivePanel,
  setActiveModal,
  VkAppInit,
} from "./actions/appActions";
import PANELS from "./panels/PANELS_NAME";

const App = (props) => {
  const {
    activePanel,
    setPanel,
    activeModal,
    setModal,
    user,
    appInit,
    isFetching,
    trips,
  } = props;

  useEffect(() => {
    appInit();
  }, [appInit]);

  const modals = <Modals modal={activeModal} setActiveModal={setModal} />;
  return (
    <View
      activePanel={activePanel}
      header={false}
      modal={modals}
      popout={isFetching ? <ScreenSpinner size="large" /> : null}
    >
      <HomePanel
        trips={trips}
        id={PANELS.INDEX}
        setActiveModal={setModal}
        go={setPanel}
        user={user}
      />
      <CreateTrip id={PANELS.CREATE_TRIP} go={setPanel} />
    </View>
  );
};

App.propTypes = {
  trips: PropTypes.array,
  activePanel: PropTypes.string,
  activeModal: PropTypes.string,
  user: PropTypes.object,
  setModal: PropTypes.func,
  setPanel: PropTypes.func,
  isFetching: PropTypes.bool,
  appInit: PropTypes.func,
};

export default connect(
  (store) => ({
    trips: store.appReducer.trips,
    activePanel: store.appReducer.activePanel,
    activeModal: store.appReducer.activeModal,
    user: store.appReducer.user,
    isFetching: store.appReducer.isFetching,
  }),
  (dispatch) => ({
    setPanel: (e) => dispatch(setActivePanel(e.currentTarget.dataset.to)),
    setModal: (e) => dispatch(setActiveModal(e)),
    appInit: () => dispatch(VkAppInit()),
  })
)(App);
