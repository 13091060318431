import React, { useState } from "react";
import PropTypes from "prop-types";
import { Group, InfoRow, Cell, Placeholder, Div } from "@vkontakte/vkui";
import { useSelector } from "react-redux";

/**
 * @param {object} objectTrip Объект поездки
 */
const TripInfo = ({ objectTrip }) => {
  const fS = 12;
  const pStyle = {
    padding: 0,
    margin: 0,
    fontSize: fS,
    maxWidth: 150,
  };
  const oldInfo = (
    <>
      <p style={pStyle}>
        Авто -{" "}
        <b>{`${objectTrip.data.detail.auto} ${objectTrip.data.detail.autoModel}`}</b>
      </p>
      <p style={pStyle}>
        Номер - <b>{objectTrip.data.detail.autoNum}</b>
      </p>
      <p style={pStyle}>
        Цвет - <b>{objectTrip.data.detail.autoColor}</b>
      </p>
      <p style={pStyle}>
        Комментарий:{" "}
        <span style={{ ...pStyle, whiteSpace: "pre-wrap", fontWeight: "bold" }}>
          {objectTrip.data.detail.comment}
        </span>
      </p>
    </>
  );
  return (
    <>
      <p style={pStyle}>
        <b>
          {objectTrip.data.detail.cityFrom +
            String.fromCharCode(32, 10144, 32) +
            objectTrip.data.detail.cityTo}
        </b>
      </p>
      {objectTrip.isFullInfo ? oldInfo : null}
    </>
  );
};

TripInfo.propTypes = {
  objectTrip: PropTypes.object,
  isFullInfo: PropTypes.bool,
};

export default TripInfo;
